import React, { Fragment, Suspense } from "react";
import Preloader from "../elements/Preloader";
const Breadcrumb = React.lazy(() => import("../components/Breadcrumb"));
// const ContactOne = React.lazy(() => import("../components/ContactOne"));
const Calc = React.lazy(() => import("../components/Calculate"));
const FooterBottomOne = React.lazy(() =>
  import("../components/FooterBottomOne")
);
const FooterTwo = React.lazy(() => import("../components/FooterTwo"));
const NavbarTwo = React.lazy(() => import("../components/NavbarTwo"));
// const PartnerOne = React.lazy(() => import("../components/PartnerOne"));
const SearchPopup = React.lazy(() => import("../elements/SearchPopup"));
const Calculate = () => {
  return (
    <>
      <Fragment>
        <Suspense fallback={<Preloader />}>
          {/* Search Popup */}
          <SearchPopup />

          {/* Navbar One */}
          <NavbarTwo />

          {/* Breadcrumb */}
          <Breadcrumb title={"CALCULATE"} />

          {/* Faq One */}
          <Calc />

          {/* Contact One */}
          {/* <div
            className='call-to-contact-area pd-top-140  mt-0'
            style={{ background: "#F9F9F9" }}
          >
            <ContactOne />
          </div> */}

          {/* Partner One */}
          {/* <PartnerOne /> */}

          {/* Footer One */}
          <FooterTwo />

          {/* Footer Bottom One */}
          <FooterBottomOne />
        </Suspense>{" "}
      </Fragment>
    </>
  );
};

export default Calculate;
